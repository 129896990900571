import { useEffect, useState } from 'react';
import { throttle } from './index.ts';

/* eslint-disable indent */
const ResponsiveSizes = ['sm', 'md', 'lg', 'xl', 'txl'] as const;

// prettier-ignore
type ResponsiveSize = typeof ResponsiveSizes[number];

const Sizes = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  txl: 1536,
};

const SizeMap: Record<ResponsiveSize, string> = Object.fromEntries(
  Object.entries(Sizes).map(([key, value]) => [key, `screen and (max-width: ${value}px)`]),
) as Record<ResponsiveSize, string>;

type ResponsiveProperty = string[] | string;

type ResponsiveProperties = Partial<Record<ResponsiveSize, ResponsiveProperty>>;

/**
 * Merge Responsive Properties or String
 */
export function rp(...responsiveProperties: (ResponsiveProperties | string)[]) {
  return responsiveProperties
    .map(responsiveProperty =>
      typeof responsiveProperty === 'string'
        ? responsiveProperty
        : Object.entries(responsiveProperty).map(([size, properties]) =>
            typeof properties === 'string'
              ? properties
              : properties.map(property => `${size}:${property}`).join(' '),
          ),
    )
    .join(' ');
}

export const getViewportSize = () => {
  if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') return 'txl';
  else if (window.matchMedia(SizeMap.sm).matches) return 'sm';
  else if (window.matchMedia(SizeMap.md).matches) return 'md';
  else if (window.matchMedia(SizeMap.lg).matches) return 'lg';
  else if (window.matchMedia(SizeMap.xl).matches) return 'xl';
  else if (window.matchMedia(SizeMap.txl).matches) return 'txl';
  else return 'txl';
};

export const useResponsive = (initialSize?: ResponsiveSize): ResponsiveSize => {
  const [size, setSize] = useState<ResponsiveSize>(initialSize ?? getViewportSize());

  useEffect(() => {
    const onResize = () => {
      setSize(getViewportSize());
    };
    const optimizedOnResize = throttle(onResize, 100);

    window.addEventListener('resize', optimizedOnResize);

    onResize();

    return () => window.removeEventListener('resize', optimizedOnResize);
  }, []);

  return size;
};

const operators = ['==', '===', '>=', '<=', '>', '<', '!=', '!=='] as const;
// prettier-ignore
type Operator = typeof operators[number];
/**
 * Compare sizes
 * params ex) "md > sm"
 */
export const compareSize = (expression: string) => {
  const [leftSize, operator, rightSize] = expression.split(' ') as [
    ResponsiveSize,
    Operator,
    ResponsiveSize,
  ];

  if (
    !ResponsiveSizes.includes(leftSize) ||
    !operators.includes(operator) ||
    !ResponsiveSizes.includes(rightSize)
  ) {
    throw new Error('This expression is wrong');
  }

  const left = ResponsiveSizes.indexOf(leftSize);
  const right = ResponsiveSizes.indexOf(rightSize);

  return eval(`${left} ${operator} ${right}`);
};