import React from 'react';
import { compareSize, useResponsive } from '../../utils/responsive.ts';

function Card(cardInfo) {
  const size = useResponsive('txl');  
  return (
    <div
      className={compareSize(`${size} <= md`) || cardInfo.isWindowLongVertically ? 'card-container-mobile' : 'card-container-web'}
      style={{ position: 'relative' }}
    >
      <div className="div2" style={{ backgroundImage: `url(${cardInfo.cardInfo.image})`, backgroundSize: 'cover' }}></div>
      <div className={`div1 ${cardInfo.cardHovered ? 'hidden' : ''}`}
        style={{ backgroundColor: `${cardInfo.cardInfo.bg_color}0.6` }}
      >
        <p
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            transform: 'rotate(90deg) translate(24px, 20px)',
            transformOrigin: 'top left', // Set the rotation point to the top-right corner
            fontWeight: 'regular',
            // padding: '10px 10px',
            whiteSpace: 'nowrap', // Prevent text from wrapping
          }}
        >
          {cardInfo.cardInfo.date}
        </p>
        <div style={{ height: '85%', padding: '0px 18px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
            {cardInfo.cardInfo.title}
          </div>
          <div 
            className={cardInfo.isLanguageEn.isLanguageEn?'':'text-kr'}
            style={{ fontSize: '12px', fontWeight: 'regular', paddingTop: '4px', minHeight:'16px' }}>
              {cardInfo.isLanguageEn.isLanguageEn ? cardInfo.cardInfo.summary_en : cardInfo.cardInfo.summary_kr}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
