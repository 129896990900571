import image_1 from './images/1.jpg';
import image_2 from './images/2.jpg';
import image_3 from './images/3.jpg';
import image_4 from './images/4.jpg';
import image_5 from './images/5.jpg';
import image_6 from './images/6.jpg';
import image_7 from './images/7.jpg';
import image_8 from './images/8.jpg';

const imageImports = [image_1,image_2,image_3,image_4,image_5,image_6,image_7,image_8]
const totalImages = imageImports.length;

const TextContents = {
  "general": {
    "main_title_en": "Hello ☺",
    "main_title_kr": "안녕하세요 ☺",
    "main_text_en_1": "I'm a Frontend Developer who can also works with UI/UX Design.",
    "main_text_en_2": "My passion is creative coding and form interactive scenes(both 2D and 3D).",
    "main_text_kr_1": "저는 프론트앤드 개발자이며 때론 UI/UX 디자인을 하기도 합니다.",
    "main_text_kr_2": "Creative Coding과 인터랙션이 가득한 2D/3D 웹 공간을 만드는 일에 큰 관심이 있습니다."
  },
  "works":{
    1:{
      "image":image_3,
      "title": "SuperbAI Website",
      "tools": "Typescript, React, Vercel, Storyblock, HTML/CSS, Figma",
      "major_role": "Development",
      "date": "2023.09",
      "summary_en": "website of superb ai",
      "summary_kr": "superb ai 소개 웹사이트",
      "description_en": "SuperbAI website is a website that introduces Superb AI, an IT startup that is located in Korea and shows the identity of the company. It contains both informative pages and brief demo sections where users can taste the vibe of one of the main products of Superb AI. My team uses Storyblock to create an ecosystem so marketers or designers who do not have any programming knowledge may add and edit web pages easily. The website covers three different views based on the language, English, Korean, and Japanese. Blog has independent contents based on the languages. The entire site is fully responsive so viewers may explore the site using any device.",
      "description_kr": "SuperbAI 웹사이트는 한국의 IT 스타트업 기업인 Superb AI의 브랜드 브랜드 아이덴티티를 소개하는 사이트입니다. 웹사이트는 정보 전달을 위한 페이지들뿐만 아니라 회사의 메인 프로덕트 중 하나의 느낌을 체험할 수 있는 섹션들도 포함하고 있습니다. Storyblock을 사용하여 프로그래밍을 모르는 마케터 혹은 디자이너들도 쉽게 페이지를 추가 혹은 수정할 수 있도록 생태계를 구축해두었습니다. 웹사이트는 언어(영어, 한국어, 일본어)에 따라 다르게 보입니다. 블로그의 경우 언어별로 완전히 독립된 컨탠츠를 가지고 있습니다.",
      "bg_color": "rgba(182, 144, 48, ",
      "video_id": 'SQaazA3yA8Q',
      "additional_link": 'https://superb-ai.com/',
      "modal_images":{
        'left_1_vertical':'https://github.com/mirinteractive/website-images/blob/main/SuperbAI_Website/left_1_vertical.png?raw=true',
        'left_2':'https://github.com/mirinteractive/website-images/blob/main/SuperbAI_Website/left_2.png?raw=true',
        'right_1':'https://github.com/mirinteractive/website-images/blob/main/SuperbAI_Website/right_1.png?raw=true',
        'right_2':'https://github.com/mirinteractive/website-images/blob/main/SuperbAI_Website/right_2.png?raw=true',
        'right_3_hero':'https://github.com/mirinteractive/website-images/blob/main/SuperbAI_Website/right_3_hero.png?raw=true',
      }
    },
    2:{
      "image": image_2,
      "title": "MICA VIBE",
      "tools": "Javascript, HTML/CSS, Processing, Adobe XD & Illustrator",
      "major_role": "Development, Web Design, Concept Development, Hardware",
      "date": "2019.05",
      "summary_en": "visualize sensors’ data and create brochure",
      "summary_kr": "센서 기반 데이터 시각화 & 7만5천개의 각기 다른 표지의 책자",
      "description_en": "MICA VIBE was a project on that school’s promotional team, graduate/undergraduate departments, communication team, and agency Karlssonwilker collaborated. The project collects students’ data from four types of sensors and randomized them as a promotional booklet. I was part of the interactive arts department that handled data collecting majorly. My team wanted to attract students and collect data instead of displaying sensors like CCTV. So we built a station with led to show the inputs of the data. The website of the project contains the visualization of the input data and explains the concept of the project.",
      "description_kr": "MICA VIBE는 교내 홍보처, 대학원, 학부, 커뮤니케이션 팀, 에이전시 Karlssonwilker가 협업하여 진행한 프로젝트였습니다. 학생들의 데이터를 수집하여 이를 랜덤으로 시각화하는 큰 틀 안에서, 제가 속해있던 Interactive Art 학과는 데이터 수집부분의 기획, 데이터 수집, 그리고 전처리를 주로 담당하였습니다. “어떤 종류의 데이터를 어떤 방식으로 수집할까?”라는 질문은 프로젝트 기획의 가장 중요한 시작점이였습니다. 한달이라는 기간안에 목표한 분량의 데이터를 모을 수 있어야하며, 자연스럽게 학생의 참여를 유도할 수 있는 중간지점을 찾아야했습니다. 단순히 0과 1의 반복인 데이터만 보여주기보다는 시각화 과정을 거친 인풋을 함께 제공하여 재미와 흥미를 이끌 수 있는 웹사이트를 완성할 수 있었습니다.",
      "bg_color": "rgba(38, 71, 135, ",
      "video_id": 'KooaGwUqtDY',
      "additional_link": 'https://drive.google.com/drive/folders/1w2SPgMYVwNO4k_5pGXTG94YHO4FngIUd?usp=sharing',
      "modal_images":{
        'left_1_vertical':'https://github.com/mirinteractive/website-images/blob/main/MICA_VIBE/left_1_vertical.PNG?raw=true',
        'left_2':'https://github.com/mirinteractive/website-images/blob/main/MICA_VIBE/left_2.png?raw=true',
        'right_1':'https://github.com/mirinteractive/website-images/blob/main/MICA_VIBE/right_1.png?raw=true',
        'right_2':'https://github.com/mirinteractive/website-images/blob/main/MICA_VIBE/right_2.png?raw=true',
        'right_3_hero':'https://github.com/mirinteractive/website-images/blob/main/MICA_VIBE/right_3_hero.png?raw=true',
      }
    },
    3:{
      "image": image_4,
      "title": "CHIVE",
      "tools": "Figma, Adobe Illustrator & Photoshop",
      "major_role": "Platform Branding, UI/UX Design",
      "date": "2021.01",
      "summary_en": "live, virtual, chef-led cooking class",
      "summary_kr": "집에서 배울 수 있는 나만의 요리교실",
      "description_en": "CHIVE is a UI/UX and branding project. It was a project about creating a live stream cooking platform that users join on air. CHIVE planned special functions that build the cooking platform stronger. It was voice UI, purchase, live stream, and bookmark functions. My team would like to create a platform that covers the entire process of shopping, making, and communicating that relates to food. My team tried to design the web as minimal as possible for users to focus on the ingredients, recipes, and cooking. Orange is one of the primary colors to appetize people. Another primary color, purple, was chosen because purple rarely appears inside the dishes so it successfully takes a role in emphasizing components.",
      "description_kr": "CHIVE는 UI/UX 디자인과 브랜딩 프로젝트입니다. 사용자가 어디서든 참여할 수 있는 live stream 요리 플랫폼을 기획 및 디자인하였습니다. 요리 플랫폼의 강점을 극대화하고자 보이스 Ui, 재료 결제 및 배송, 라이브 스트리밍, 그리고 북마크 기능을 활용하였습니다. 단순히 요리하는 것이 아닌 요리를 준비하는 과정부터 요리가 끝나고 다른 사용자와 공유/소통할 수 있는 전반적인 프로세스를 커버하는 플랫폼을 기획하는 것이 목표였습니다. 사용자가 요리에 집중할 수 있도록 미니멀한 디자인과 그래픽을 사용하였습니다. 입맛을 돋우는 오렌지 색상을 primary color로 활용하였습니다. 보라색 또한 primary color로 활용하였는데, 이는 보라색은 요리에서 잘 사용되지 않아 강조해야 하는 부분을 성공적으로 보일 수 있기 때문입니다.",
      "bg_color": "rgba(255, 128, 0, ",
      "video_id": 'IIg-hpd8YE0',
      "additional_link": 'https://drive.google.com/drive/folders/1tLF5qfM-pgEbvwwdEspSpig5XV0n-Te8?usp=sharing',
      "modal_images":{
        'left_1_vertical':'https://github.com/mirinteractive/website-images/blob/main/CHIVE/left_1_vertical.PNG?raw=true',
        'left_2':'https://github.com/mirinteractive/website-images/blob/main/CHIVE/left_2.png?raw=true',
        'right_1':'https://github.com/mirinteractive/website-images/blob/main/CHIVE/right_1.gif?raw=true',
        'right_2':'https://github.com/mirinteractive/website-images/blob/main/CHIVE/right_2.png?raw=true',
        'right_3_hero':'https://github.com/mirinteractive/website-images/blob/main/CHIVE/right_3_hero.png?raw=true',
      }
    },
    4:{
      "image": image_7,
      "title": "Sound Pond",
      "tools": "Javascript, React, Vercel, HTML/CSS(canvas), Figma",
      "major_role": "Development, Design",
      "date": "2022.08",
      "summary_en": "music visualizer",
      "summary_kr": "음악 시각화",
      "description_en": "Sound Pond is a music visualizer that shows activations of specific sounds. The scene forms with circles that represent raindrops on the pond. It can interact with both sound and cursor position. The project mainly uses React, Javascript, and Canvas (CSS).",
      "description_kr": "Sound Pond는 음악의 특정 소리를 시각화 한 music visualizer입니다. 화면은 비 내리는 연못의 물결을 시각화 한 원들로 이루어져 있습니다. 원들은 소리와 마우스 커서 위치에 반응합니다. 해당 프로젝트는 리액트, 자바스크립트, 그리고 캔버스 (CSS)를 주로 사용하였습니다.",
      "bg_color": "rgba(100, 62, 142, ",
      "video_id": 'e4bPaqqI7xA',
      "additional_link": 'https://mouse-music-interaction.vercel.app/',
      "modal_images":{
        'left_1_vertical':'https://github.com/mirinteractive/website-images/blob/main/Sound_Pond/left_1_vertical.png?raw=true',
        'left_2':'https://github.com/mirinteractive/website-images/blob/main/Sound_Pond/left_2.png?raw=true',
        'right_1':'https://github.com/mirinteractive/website-images/blob/main/Sound_Pond/right_1.png?raw=true',
        'right_2':'https://github.com/mirinteractive/website-images/blob/main/Sound_Pond/right_2.png?raw=true',
        'right_3_hero':'https://github.com/mirinteractive/website-images/blob/main/Sound_Pond/right_3_hero.png?raw=true',
      }
    },
    5:{
      "image": image_5,
      "title": "Flow Segment",
      "tools": "Javascript, React, Vercel, HTML/CSS(canvas), Figma",
      "major_role": "Development, Design",
      "date": "2023.09",
      "summary_en": "canvas image slider",
      "summary_kr": "캔버스 이미지 슬라이더",
      "description_en": "Flow Segment is an image slider that users can control the images' order. Users may use either mouse hover or click to move to the next image. The mouse cursor masks the image and gives a reversed effect when it places over. The project mainly uses React, Javascript, and Canvas (CSS)",
      "description_kr": "Flow Segment는 이미지가 순서를 사용자가 자유롭게 컨트롤할 수 있는 사진 슬라이더입니다. 사용자는 마우스로 특정 영역을 hover 혹은 클릭하여 다음 이미지로 이동할 수 있습니다. 마우스 커서가 사진 위에 올라가면 해당 영역의 이미지의 색상은 반전됩니다. 해당 프로젝트는 리액트, 자바스크립트, 그리고 캠버스 (CSS)를 주로 사용하여 제작하였습니다.",
      "bg_color": "rgba(33, 48, 91, ",
      "video_id": 'zZ5xS3FKxb8',
      "additional_link": 'https://flow-segment.vercel.app/',
      "modal_images":{
        'left_1_vertical':'https://github.com/mirinteractive/website-images/blob/main/Flow_Segment/left_1_vertical.png?raw=true',
        'left_2':'https://github.com/mirinteractive/website-images/blob/main/Flow_Segment/left_2.png?raw=true',
        'right_1':'https://github.com/mirinteractive/website-images/blob/main/Flow_Segment/right_1.png?raw=true',
        'right_2':'https://github.com/mirinteractive/website-images/blob/main/Flow_Segment/right_2.png?raw=true',
        'right_3_hero':'https://github.com/mirinteractive/website-images/blob/main/Flow_Segment/right_3_hero.png?raw=true',
      }
    },
    6:{
      "image": image_8,
      "title": "Wayne Gallery",
      "tools": "Javascript(three.js), HTML/CSS(canvas), Figma",
      "major_role": "Development",
      "date": "2022.02",
      "summary_en": "3d gallery for motion artworks",
      "summary_kr": "3d 모션 아트웍 겔러리",
      "description_en": "Waynehills Space is a project that explores the strength of web-based new media. The first space calls Waynehills Gallery. Waynehills Gallery displays NFT artworks with various forms of images, videos, and 3d models. It provides both keyboard and scroll mode so users may choose how to explore the scene. Mobile provides scroll mode only for convenience. The gallery visualizes different user events like scroll speed, x/y/z position that attract users. The second space calls Waynehills MediaWall inspired by realistic content on large-scale billboards. Largest billboard renders youtube playlists. The administrator of the space only needs to change the Youtube playlist, which means they do not have to edit code directly and control what users may see.",
      "description_kr": "Waynehills Space는 사용성과 뉴 미디어를 중심으로 '메타 스페이스'의 강점을 실험해 본 공간입니다. 첫 번째 공간인 Waynehills Gallery는 사진, 동영상, 3D Model 등 다양한 형식의 NFT 아트워크를 전시한 공간입니다. 키보드와 스크롤 모드를 제공하여 선택에 따라 아트워크를 감상할 수 있는 특징을 가지고 있습니다. 모바일에선 스크롤 모드만을 제공합니다. 스크롤 속도, 좌표값 등 다양한 이벤트를 시각화하여 사용자의 흥미를 유도합니다. 두 번째 공간인 Waynehills MediaWall은 빌보드에서 많이 사용하는 실감형 스크린에 영감을 받았습니다. 가장 큰 스크린은 유튜브 플레이리스트를 렌더링 합니다. 공간에서 보일 수 있는 영상을 외부(유튜브)에서 제어할 수 있도록 만들었습니다. 이는 관리자가 코드를 수정하지 않아도 사용자에게 보이는 영상을 손쉽게 제어할 수 있다는 특징을 가지고 있습니다.",
      "bg_color": "rgba(158, 148, 49, ",
      "video_id": 'Cv4zf1n5vTg',
      "additional_link": 'https://miri-waynehills.github.io/waynehills-gallery/',
      "modal_images":{
        'left_1_vertical':'https://github.com/mirinteractive/website-images/blob/main/Wayne_Gallery/left_1_vertical.png?raw=true',
        'left_2':'https://github.com/mirinteractive/website-images/blob/main/Wayne_Gallery/left_2.png?raw=true',
        'right_1':'https://github.com/mirinteractive/website-images/blob/main/Wayne_Gallery/right_1.png?raw=true',
        'right_2':'https://github.com/mirinteractive/website-images/blob/main/Wayne_Gallery/right_2.png?raw=true',
        'right_3_hero':'https://github.com/mirinteractive/website-images/blob/main/Wayne_Gallery/right_3_hero.png?raw=true',
      }
    },
    7:{
      "image": image_1,
      "title": "Lady Justitia",
      "tools": "Javascript(three.js, gsap), React, Vercel, HTML/CSS(canvas), Figma",
      "major_role": "Development, Design",
      "date": "2023.09",
      "summary_en": "interactive website using 3d model",
      "summary_kr": "3d 모델을 활용한 린터랙티브 웹사이트",
      "description_en": "Lady Justitia is a narrated scene that introduces Goodies of Justices using 3D model. The page is divided into four sections. Each section shows symbols that Justitia has had since ancient Rome. The project mainly uses React and Javascript libraries (react three fiber and gsap). The model is from TurboSquid, which is on sale.",
      "description_kr": "Lady Justitia는 3d 모델을 활용하여 정의의 여신 Justitia를 소개하는 프로젝트입니다. 화면은 모델의 형상을 기반으로 네 개로 나누어져 있습니다. 각각의 섹션은 고대 로마 시기부터 Justitia를 상징해온 것들을 소개합니다. 해당 프로젝트는 리액트와 자바스크립트 라이브러리들 (react three fiber, gsap)을 주로 사용하였습니다. 사용한 모델은 ᄋᄋᄋ에서 구매하여 사용 하였습니다.",
      "bg_color": "rgba(0, 0, 0, ",
      "video_id": '5Qt6z9J57j4',
      "additional_link": 'https://lady-justitia.vercel.app/',
      "modal_images":{
        'left_1_vertical':'https://github.com/mirinteractive/website-images/blob/main/Lady_Justitia/left_1_vertical.png?raw=true',
        'left_2':'https://github.com/mirinteractive/website-images/blob/main/Lady_Justitia/left_2.png?raw=true',
        'right_1':'https://github.com/mirinteractive/website-images/blob/main/Lady_Justitia/right_1.png?raw=true',
        'right_2':'https://github.com/mirinteractive/website-images/blob/main/Lady_Justitia/right_2.png?raw=true',
        'right_3_hero':'https://github.com/mirinteractive/website-images/blob/main/Lady_Justitia/right_3_hero.png?raw=true',
      }
    },
    8:{
      "image": image_6,
      "title": "Space W",
      "tools": "Javascript(three.js, gsap), HTML/CSS(canvas), GLSL(Shader), Adobe XD",
      "major_role": "Development, Design",
      "date": "2022.01",
      "summary_en": "interactive promotional website",
      "summary_kr": "2D/3D 회사 홍보 페이지",
      "description_en": "Space W is a webpage that introduces the vibe and path that Waynehills Ventures follows. The site formed a mixture of 2D and 3D for the unique illustrative visual. Since the web page is promotional, it should deliver the content effectively. My team decided to provide a specific position to the camera instead of freedom to move around. I created logic to move the camera around the base on the amount that the mouse scrolled. One of the negative aspects of the illustrational view is decreasing in readability when the screen size decrease. To resolve the issue, I decide to adjust the camera distance and video/text size so users may enjoy the vibe.",
      "description_kr": "Space W는 웨인힐스벤처스가 추구하는 방향성과 회사의 부서를 간략하게 소개하는 웹 페이지입니다. 시각적으로 차별화된 공간을 선보이고자 일러스트 느낌으로 비주얼을 기획하였으며, 이 과정에서 3D와 2D를 혼합하여 사용하였습니다. 해당 웹 페이지는 정보 전달의 목적이 크다고 판단하여 카메라 이동의 자율성보단 동선을 지정하는 방식으로 공간을 구연하였습니다. 따라서 스크롤로 카메라 위치의 전환을 진행하도록 로직을 구현하였습니다. 일러스트레이션 형식의 공간 특성상 화면 크기가 작아질수록 텍스트의 가독성이 떨어진다고 생각하였습니다. 따라서 사이트의 동영상과 공간 자체의 분위기를 느낄 수 있도록 오브젝트 크기와 카메라 거리를 조절하였습니다.",
      "bg_color": "rgba(64,109,77, ",
      "video_id": 'EN7MMKLSoso',
      "additional_link": 'https://miri-waynehills.github.io/space-w/',
      "modal_images":{
        'left_1_vertical':'https://github.com/mirinteractive/website-images/blob/main//Space_W/left_1_vertical.png?raw=true',
        'left_2':'https://github.com/mirinteractive/website-images/blob/main/Space_W/left_2.png?raw=true',
        'right_1':'https://github.com/mirinteractive/website-images/blob/main/Space_W/right_1.png?raw=true',
        'right_2':'https://github.com/mirinteractive/website-images/blob/main/Space_W/right_2.png?raw=true',
        'right_3_hero':'https://github.com/mirinteractive/website-images/blob/main/Space_W/right_3_hero.png?raw=true',
      }
    }
  },
  "about": {
    "main_title_en": "Hello, I'm Miri",
    "main_title_kr": "안녕하세요, 김미리입니다",
    "main_text_en_1": "I design and create interesting STUFF that happens online.",
    "main_text_en_2": "My works focus on user-friendliness. I have a high interest in interaction, responsiveness, support translation, and performance to achieve my goal.",
    "main_text_en_3": "I would love to become a generalist who can cover various web environments like 2D/3D.",
    "main_text_en_4": "I'm eager to try new styles, challenge myself, and make it a high-end outcome.",
    "main_text_kr_1": "저는 사용자가 흥미로운 경험을 할 수 있는 온라인 환경을 디자인 및 구현합니다.",
    "main_text_kr_2": "소외되는 사용자가 없는 결과물을 만들기 위하여 노력합니다. 이를 효과적으로 달성할 수 있도록 인터랙션, 반응형, 언어 간 번역, 그리고 퍼포먼스에 큰 관심을 가지고 있습니다.",
    "main_text_kr_3": "2D/3D 등 특정 스타일에 국한되지 않는 generalist가 되기 위하여 다양한 시도를 하고 있습니다.",
    "main_text_kr_4": "새로운 스타일을 기획/디자인하고, 완성도 높은 결과물을 구현할 수 있는 기회를 찾고 있습니다.",
    "what_i_can_do_en_1": "Design and Develop Creative Website (with spice of interaction and responsiveness)",
    "what_i_can_do_en_2": "Create 2D/3D space online (using canvas, webGL, 3D model)",
    "what_i_can_do_kr_1": "Creative Website 디자인 및 개발 (인터랙션과 반응형 포함)",
    "what_i_can_do_kr_2": "온라인 2D/3D 공간 제작 (canvas, webGL, 3D 모델 사용)",
    },
  }

  const loadedImages = Object.values(TextContents.works).map((project) => project.image).filter((image) => !!image).length;
  const loadingPercentage = (loadedImages / totalImages) * 100;

  export {TextContents, loadingPercentage};
  