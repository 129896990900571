import React, { useEffect, useState, useRef } from 'react';
import { compareSize, useResponsive } from '../utils/responsive.ts';
import { TextContents, loadingPercentage } from '../contents/TextContents.jsx';
import Card from '../components/molecules/Card.jsx';
import Modal from './Modal.jsx';
import Footer from '../components/organisms/Footer.jsx';
import LoadingScreen from './LoadingScreen.jsx';
import './Home.css'

const colors = ['red', 'blue', 'green', 'orange', 'purple'];
const strongColors = ['rgb(255, 0, 0)', 'rgb(0, 0, 255)', 'rgb(0, 255, 0)'];

function Home(isLanguageEn) {
  const [rotation, setRotation] = useState(0);
  const [isRotating, setIsRotation] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState('');
  const [hoveredCardIndex, setHoveredCardIndex] = useState();
  const [hoveredCardBackgroundColor, setHoveredCardBackgroundColor] = useState('rgba(21, 21, 21, ');
  const [isCardHovered, setIsCardHovered] = useState(false)
  const [isTextHovered, setIsTextHovered] = useState(false);
  const [copiedText, setCopiedText] = useState('');
  const [copiedTextColor, setCopiedTextColor] = useState('');
  const [copiedElements, setCopiedElements] = useState([]);
  const [draggedElement, setDraggedElement] = useState(null);
  const [windowInnerWidth, setWindowInnerWidth] = useState(0);
  const [windowInnerHeight, setWindowInnerHeight] = useState(0);
  const [isWindowLongVertically, setIsWindowLongVertically] = useState(false);

  const size = useResponsive('txl');
  const timerRef = useRef(null);
  const animation = isTextHovered ? 'changeColorStrong 1s infinite' : 'changeColor 5s infinite';
  
  useEffect(() => {
    setWindowInnerWidth(window.innerWidth)
    setWindowInnerHeight(window.innerHeight)

    if(windowInnerWidth-windowInnerHeight >= 0){
      setIsWindowLongVertically(false)
    } else {
      setIsWindowLongVertically(true)
    }

  }, [windowInnerWidth, windowInnerHeight, isWindowLongVertically]);

  useEffect(() => {
    const handleScroll = (event) => {
      if (!isModalOpen || loadingPercentage===100) {
        clearTimeout(timerRef.current);
        const newRotation = rotation + event.deltaY / 5;
        setRotation(newRotation);
        setIsRotation(true);

        timerRef.current = setTimeout(() => {
          setIsRotation(false);
        }, 1000);
      }
    };

    const rotateInterval = setInterval(() => {
      if (!isRotating && !isModalOpen) {
        setRotation((prevRotation) => prevRotation + 0.06);
      }
    }, 16);

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
      clearInterval(rotateInterval);
    };
  }, [isRotating, rotation, isModalOpen]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({
        x: event.clientX / 50,
        y: event.clientY / 50,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleRingItemClick = (itemIndex) => {
      if (!isModalOpen) {
          const workKeys = Object.keys(TextContents.works);
          if (workKeys.length > itemIndex) {
            const selectedKey = workKeys[itemIndex];
            setIsModalOpen(true)
            setSelectedContent(TextContents.works[selectedKey]);
            setIsRotation(false);
      }
    }
  };
  

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedContent('');
  };

  useEffect(() => {
    const disableScroll = () => {
      if (isModalOpen || loadingPercentage!==100) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    };

    disableScroll();

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  const handleHover = (index) => {
    setHoveredCardIndex(index);
    setHoveredCardBackgroundColor(TextContents.works[index+1].bg_color)
    setIsCardHovered(true)
  };

  const handleMouseLeave = () => {
    setHoveredCardIndex();
    setHoveredCardBackgroundColor('rgba(21, 21, 21, ')
    setIsCardHovered(false)
  };

  const headerStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    animation,
    // color: isTextHovered ? strongColors[0] : colors[0], // Set the initial color
    width:'100%',
    maxWidth:'440px',
    textAlign:'start'
  };

  const handleTextClick = () => {
    const textElement = document.querySelector('.text-header');
    const computedStyle = getComputedStyle(textElement);
    const textColor = computedStyle.color;
    const textContent = textElement.textContent;

    // Set the copied text and its color
    setCopiedText(textContent);
    setCopiedTextColor(textColor);
  };

  const handleMouseClick = (e) => {
    if (copiedText && copiedTextColor) {
      // Create a new div element
      const newCopiedElement = (
        <button
          key={copiedElements.length}
          style={{
            position: 'fixed',
            top: `${position.y * 50}px`,
            left: `${position.x * 50}px`,
            fontSize: '18px',
            fontWeight: isLanguageEn.isLanguageEn? 'regular': 'bold',
            backgroundColor: copiedElements.length%2===1? '#ffffff':'#000000',
            // backgroundColor: '#eaeaea',
            // backgroundColor: '#000000',
            padding: '5px 10px',
            borderRadius: 10,
            color: copiedTextColor,
          }}
          onClick={() => handleRemoveCopiedElement(copiedElements.length)}
        >
          {copiedText}
        </button>
      );
  
      // Update the copiedElements state with the new element
      setCopiedElements([...copiedElements, newCopiedElement]);
  
      // Clear the copied text and color
      setCopiedText('');
      setCopiedTextColor('');
    }
  };

  const handleRemoveCopiedElement = (index) => {
    // Create a copy of the copiedElements array and remove the element at the specified index
    const updatedCopiedElements = [...copiedElements];
    updatedCopiedElements.splice(index, 1);
  
    // Update the copiedElements state with the modified array
    setCopiedElements(updatedCopiedElements);
  };
  
  useEffect(() => {
    document.addEventListener('click', handleMouseClick);
  
    return () => {
      document.removeEventListener('click', handleMouseClick);
    };
  }, [copiedText, copiedTextColor, copiedElements]);

  const handleDragStart = (e, index) => {
    // Set the dragged element and its initial position
    setDraggedElement(index);
    e.dataTransfer.setData('text/plain', index);
  };
  
  const handleDrag = (e) => {
    // console.log(e.clientX, e.clientY);
  };
  
  const handleDragEnd = (e) => {
    // if(e.clientX||e.clientY===0) 
    // Reset the dragged element
    const updatedElements = [...copiedElements];
      updatedElements[draggedElement] = React.cloneElement(copiedElements[draggedElement], {
        style: {
          ...copiedElements[draggedElement].props.style,
          top: `${e.clientY}px`,
          left: `${e.clientX}px`,
        },
      });
  
      setCopiedElements(updatedElements);
      setDraggedElement(null);
  };

  return (
    <div className='container' 
      style={{display:'flex', justifyContent:'center', 
        backgroundColor: `${hoveredCardBackgroundColor}0.6)`
      }}>
      {compareSize(`${size} <= md`) || isWindowLongVertically ? (
        <div className="container-mobile">
          <div style={{padding:'40px 24px 0px 24px' ,color:'#eaeaea', backgroundColor:'#1b1b1b'
      }}>
            <div className={`text-header 'strong'`}
              onClick={handleTextClick}
              onMouseEnter={() => setIsTextHovered(true)}
              onMouseLeave={() => setIsTextHovered(false)}
            >
              { isLanguageEn.isLanguageEn ? TextContents.general.main_title_en : TextContents.general.main_title_kr}
            </div>
            <div className={isLanguageEn.isLanguageEn?'':'text-kr'} style={{ paddingTop:'10px', paddingBottom:'60px' ,fontSize:'16px', lineHeight:'1.2', maxWidth:'440px'}}>
              <div>{ isLanguageEn.isLanguageEn ? TextContents.general.main_text_en_1 : TextContents.general.main_text_kr_1}</div>
              <div>{ isLanguageEn.isLanguageEn ? TextContents.general.main_text_en_2 : TextContents.general.main_text_kr_2}</div>
            </div>
          </div>
          {Object.keys(TextContents.works).map((key, index) => (
            <div
            key={key}
            onClick={() => handleRingItemClick(index)}
            >
                <Card
                cardInfo={TextContents.works[key]}
                cardHovered={index === hoveredCardIndex}
                isLanguageEn={isLanguageEn}
                isWindowLongVertically={isWindowLongVertically}
                // style={{width:'100vw', overflow:'hidden'}} 
              />
            </div>
          ))}
          <Footer />
      </div>
      ) : (
        <div className="container-web" style={{overflow:'hidden'}}>
          <button className="copied-elements" style={{opacity:isCardHovered?0:1}}>
            {copiedElements.map((element, index) => (
              <div
              key={index}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, index)}
              onDrag={(e) => handleDrag(e, index)}
              onDragEnd={handleDragEnd}
            >
              {element}
            </div>
            ))}
          </button>
          <div id="ring-container">
            <div
              id="ring"
              style={{
                transform: `rotateY(${rotation}deg) translate(${position.x}px, ${position.y}px)`,
              }}
            >
              {Object.keys(TextContents.works).map((key, index) => (
                <div
                  key={key}
                  className="ring-item"
                  onClick={() => handleRingItemClick(index)}
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Card
                    cardInfo={TextContents.works[key]}
                    cardHovered={index === hoveredCardIndex}
                    isLanguageEn={isLanguageEn}
                    isWindowLongVertically={isWindowLongVertically}
                  />
                </div>
              ))}
            </div>
          </div>
          <div id='text-container' >
            <button className={`text-header ${isTextHovered ? 'strong' : ''}`}  style={headerStyle}
              onClick={handleTextClick}
              onMouseEnter={() => setIsTextHovered(true)}
              onMouseLeave={() => setIsTextHovered(false)}
            >
              { isLanguageEn.isLanguageEn ? TextContents.general.main_title_en : TextContents.general.main_title_kr}
            </button>
            <div style={{ paddingTop:'10px', paddingBottom:'60px' ,fontSize:'15px', lineHeight:'1.2', maxWidth:'440px'}}>
              <div>{ isLanguageEn.isLanguageEn ? TextContents.general.main_text_en_1 : TextContents.general.main_text_kr_1}</div>
              <div>{ isLanguageEn.isLanguageEn ? TextContents.general.main_text_en_2 : TextContents.general.main_text_kr_2}</div>
            </div>
          </div>
      </div>
    )}
      {isModalOpen && (
        <Modal 
          open={isModalOpen} 
          onClose={closeModal} 
          content={selectedContent}
          isLanguageEn={isLanguageEn.isLanguageEn}
         />
    )}
    {loadingPercentage!==100&&(<LoadingScreen loadingPercentage={loadingPercentage} />)}
    </div>
  );
}

export default Home;