import React from 'react';
import xIcon from "../contents/images/icon-x.svg"
import linkIcon from '../contents/images/icon-link.svg'
import { compareSize, useResponsive } from '../utils/responsive.ts';
import './Modal.css'

function Modal({ open, onClose, content, isLanguageEn }) {
  const size = useResponsive('txl');

  const handleModalClick = (e) => {
    if (e.target.classList.contains('modal-outer')) {
      onClose();
    }
  };

  if (!open) return null;

  return (
    <div className="modal-outer" onClick={handleModalClick}>
      <div className="modal-container"
         style={{
          marginBottom:compareSize(`${size} <= lg`) ? '80px':'0px',
          // paddingTop:compareSize(`${size} <= lg`) ? '60px':'0px',
        }} 
      >
        <div>
          { compareSize(`${size} <= lg`) ? 
            <div 
              style={{position:'absolute', top:'30px', left:'24px',
                display:'flex', alignItems:'center', gap:'10px', color:'#eaeaea', paddingBottom:'6px'
              }}>
              <h2>{content.title}</h2>
              <a href={content.additional_link} target="_blank"><img style={{width:'16px'}} src={linkIcon} /></a>
            </div>:null}
          <button className="modal-close-button" onClick={onClose}><img src={xIcon} /></button>
        </div>
        <div 
          style={{display:'flex', 
            padding:compareSize(`${size} <= lg`) ? '70px 0px':'0px',
            flexDirection: compareSize(`${size} <= lg`) ? 'column':'row', overflow:'scroll'
          }}>
          <div id='video'>
            <iframe
              style={{
                width: compareSize(`${size} <= lg`) ? '100%':'64vw', 
                height:compareSize(`${size} <= lg`) ? '50vh':'66vh',
                maxWidth:'1000px', backgroundColor: '#151515',
              }} 
              src={`https://www.youtube.com/embed/${content.video_id}?rel=0&autoplay=1&controls=0`} 
              content="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay; modestbranding"></iframe>
          </div>
          <div
            style={{
              // marginTop: compareSize(`${size} <= lg`) ? '0px':'15px', 
              padding: compareSize(`${size} <= lg`) ? '0px':'10px 30px', 
              color:'#eaeaea', backgroundColor: '#151515',
            }}
           >
            <div 
              style={{
                display:compareSize(`${size} <= lg`) ?'none':'flex', 
                alignItems:'center', gap:'10px', 
              }}>
              <h2>{content.title}</h2>
              <a href={content.additional_link} target="_blank"><img style={{width:'16px', paddingTop:'6px'}} src={linkIcon} /></a>
            </div>
            <h3 style={{paddingTop:'6px', paddingBottom:'24px'}}>{content.summary}</h3>
            <div style={{display:'flex', flexDirection:'column', gap:'10px', paddingBottom:'24px'}}>
              <div>
                <p style={{fontWeight:'bold', paddingBottom:'4px'}}>Tools</p>
                <p>{content.tools}</p>
              </div>
              <div>
                <p style={{fontWeight:'bold', paddingBottom:'4px'}}>Major Role</p>
                <p>{content.tools}</p>
              </div>
              <div>
                <p style={{fontWeight:'bold', paddingBottom:'4px'}}>Created Date</p>
                <p>{content.date}</p>
              </div>
            </div>
            <div>
              <p style={{fontWeight:'bold', paddingBottom:'6px'}}>Project Summary</p>
              <p className={isLanguageEn?'':'text-kr'}>{isLanguageEn?content.description_en:content.description_kr}</p>
            </div>
          </div>
        </div>
        {compareSize(`${size} <= lg`) ? 
          <div style={{display:'flex', flexDirection:'column', gap:'20px', margin:'20px 0px', backgroundColor: '#151515',}}>
            <div style={{maxHeight:'220px', overflow:'hidden'}}>
              <img style={{width:'100%', height:'auto'}} src={content.modal_images.right_3_hero} />
            </div>
            <img style={{width:'100%', height:'auto'}} src={content.modal_images.right_1} />
            <img style={{width:'100%', height:'auto'}} src={content.modal_images.left_2} />
            <img style={{width:'100%', height:'auto'}} src={content.modal_images.left_1_vertical} />
            <img style={{width:'100%', height:'auto'}} src={content.modal_images.right_2} />
          </div>
        :
          <div style={{display:'flex', flexDirection:'row', gap:'20px', maxHeight:'720px',
            overflow:'hidden', margin:'20px 0px', backgroundColor: '#151515',
          }}>
            <div style={{width:'30%', height:'auto', display:'flex', flexDirection:'column', gap:'20px'}}>
              <img style={{width:'100%', height:'auto'}} src={content.modal_images.left_1_vertical} />
              <img style={{width:'100%', height:'auto'}} src={content.modal_images.left_2} />
            </div>
            <div style={{width:'70%', height:'auto', display:'flex', flexDirection:'column', gap:'20px'}}>
              <div style={{display:'flex', gap:'20px'}}>
                <img style={{width:'49%', height:'auto'}} src={content.modal_images.right_1} />
                <img style={{width:'49%', height:'auto'}} src={content.modal_images.right_2} />
              </div>
              <img style={{width:'100%', height:'auto'}} src={content.modal_images.right_3_hero} />
            </div>
          </div> 
        }
      </div>
    </div>
  );
}

export default Modal;

