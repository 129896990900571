import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactUs() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_rxdo5ve', 'template_i8ar5br', form.current, 'rY2vqakXEEzXp5IXe')
      .then((result) => {
        console.log(result.text);
        // Clear the form fields
        form.current.reset();
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail} style={{ display: 'flex', flexDirection: 'column', gap: 12, padding: '8px 0px' }}>
      <input type="text" name="from_name" placeholder="name" style={{ width: '200px', padding: '4px' }} required />
      <input type="email" name="user_email" placeholder="email" style={{ width: '200px', padding: '4px' }} required />
      <textarea name="message" placeholder="tell me anything that you want!" style={{ height: '70px', padding: '4px' }} required />
      <input type="submit" value="Send" style={{ width: '80px', padding: '8px 4px', color: '#eaeaea' }} />
    </form>
  );
}
