import React, { useEffect, useRef, useState } from 'react';

function Effect(canvasInformation) {
  const [showEmoji, setShowEmoji] = useState(false); 
  const canvasRef = useRef(null);
  let canvas, ctx, w, h;
  let units;
  let area = {
    distance: 20,
    padding: 30,
    cols: 0,
    rows: 0,
  };
  let pointer = { x: 0, y: 0 };

  class Unit {
    constructor(col, row) {
      this.x = area.distance * (col + 0.5) + area.padding;
      this.y = area.distance * (row + 0.5) + area.padding;
      this.originalW = 18;
      this.originalH = 3;
      this.w = this.originalW; 
      this.h = this.originalH; 
      this.spins = false;
    }

    draw() {
      ctx.save();
      ctx.translate(this.x, this.y);
      ctx.rotate(this.angle);
      ctx.scale(this.scale, this.scale);
      ctx.fillStyle = `rgba(255, 255, 255, ${this.alpha})`;
      ctx.fillRect(-this.w / 2, -this.h / 2, this.w, this.h);
      ctx.restore();
    }

    update() {
      if (!this.spins) {
        this.angle = Math.atan2(pointer.y - this.y, pointer.x - this.x);

        this.distance = Math.sqrt(
          Math.pow(this.x - pointer.x, 2) + Math.pow(this.y - pointer.y, 2)
        );

        this.alpha = this.distance / (w * 0.3);
        this.alpha = Math.max(0.2, 1- Math.min(1, this.alpha));

        this.w = this.originalW * (1 - this.distance / (w * 0.7));
        this.h = this.originalH * (1 - this.distance / (w * 0.2));
      } else {
        this.angle += 0.3;
      }
    }
  }

  function init() {
    canvas = canvasRef.current;
    if (canvas) {
      ctx = canvas.getContext('2d');
      resizeReset();
      drawScene();
    }
  }
  
  function resizeReset() {
    canvas = canvasRef.current;
    if (canvas) {
      w = canvas.width = window.innerWidth/canvasInformation.canvasWidth;
      h = canvas.height = window.innerHeight/canvasInformation.canvasHeight;
      area.cols = Math.floor((w - area.padding * 2) / area.distance);
      area.rows = Math.floor((h - area.padding * 2) / area.distance);
      pointer.x = area.distance * (area.cols / 2) + area.padding;
      pointer.y = area.distance * (area.rows / 2) + area.padding;
      units = [];
      for (let i = 0; i < area.rows; i++) {
        for (let j = 0; j < area.cols; j++) {
          units.push(new Unit(j, i));
        }
      }
    }
}

function mousemove(e) {  
  setShowEmoji(false);
  if (!ctx) {
      init();
  }

  pointer.x = e.nativeEvent.offsetX;
  pointer.y = e.nativeEvent.offsetY;

  if (ctx && units) {
      ctx.clearRect(0, 0, w, h);
      drawScene();
  }
}

function click() {
  if(!canvasInformation.mouseClick) return

  resizeReset();
  setShowEmoji(true);
}

  function drawScene() {
    units.forEach((unit) => {
      unit.update();
      unit.draw();
    });
  }

  useEffect(() => {
    init();
    window.addEventListener('resize', resizeReset);
    return () => {
      window.removeEventListener('resize', resizeReset);
    };
  }, []);

  return <div style={{display:'flex', justifyContent:'center', alignItems:'center', cursor:'none'}}>
    <canvas ref={canvasRef} onMouseMove={mousemove} onClick={click} />
    <div style={{position:'absolute', display:showEmoji?"block":'none', fontSize:'100px'}}>😉</div>
  </div>;
}

export default Effect;
