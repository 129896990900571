import React, { useEffect, useState, useRef } from 'react';

function Footer() {
  const [koreaTime, setKoreaTime] = useState(getKoreaTime()); // Initialize with current time

  function getKoreaTime() {
    const options = {
      timeZone: 'Asia/Seoul',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return new Date().toLocaleTimeString('en-US', options);
  }

  // Update the time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setKoreaTime(getKoreaTime());
    }, 1000); // Update every second

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <div className="footer-container">
      <div>Feel free to <a href="mailto: inadvanced8282@gmail.com" style={{fontSize:'14px', fontWeight:'bold', borderBottom:'1px solid'}}>email</a> me ☺</div>
      <div style={{display:'flex', gap:'8px', fontSize:'14px',}}>
        <span style={{fontWeight:'bold'}}>&copy; Miri Kim</span>
        <span>Seoul, Korea {koreaTime}</span>
      </div>
    </div>
  );
}

export default Footer;