// Router.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { compareSize, useResponsive } from "./utils/responsive.ts"
import Home from './pages/Home.jsx';
import About from './pages/About.jsx';
import logo from './contents/images/logo.svg'
import iconEn from './contents/images/icon-lang-en.svg'
import iconKr from './contents/images/icon-lang-kr.svg'
import iconHamburger from './contents/images/icon-hamburger.svg'
import iconX from './contents/images/icon-x.svg'

function AppRouter() {
  const size = useResponsive('txl');
  const [isLanguageEn, setIsLanguageEn] = useState(true);
  const [koreaTime, setKoreaTime] = useState(getKoreaTime()); // Initialize with current time
  const [isOpen, setIsOpen] = useState(false);

  function getKoreaTime() {
    const options = {
      timeZone: 'Asia/Seoul',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return new Date().toLocaleTimeString('en-US', options);
  }

  // Update the time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setKoreaTime(getKoreaTime());
    }, 1000); // Update every second

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Load the language preference from local storage when the component mounts
  useEffect(() => {
    const storedLanguage = localStorage.getItem('isLanguageEn');
    if (storedLanguage !== null) {
      setIsLanguageEn(storedLanguage === 'true');
    }
  }, []);

  // Save the language preference to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('isLanguageEn', isLanguageEn);
  }, [isLanguageEn]);

  return (
    <Router>
      <div style={{
        display:'flex',
        justifyContent:'center',
      }}>
        {compareSize(`${size} <= md`) ? (
          <div style={{position:'fixed',width:'100vw', height:'80px', display:'flex', alignItems:'center', zIndex:8, backgroundColor:'#151515',}}>
            <a href="/">
              <span style={{ fontSize:'18px' ,fontWeight:'bold', paddingLeft:'24px'}}>MIRIKIM</span>
              {/* <img style={{width:'38px', height:'auto'}} src={logo} /> */}
            </a>
            <div className={`hamburger-menu ${isOpen ? 'open' : ''}`}>
              <div className="overlay" onClick={toggleMenu}></div>
              <div className="hamburger-icon" onClick={toggleMenu}>
                <img src={!isOpen ? iconHamburger : iconX} alt="Hamburger Icon" />
              </div>
              <div className={`menu ${isOpen ? 'open' : ''}`}>
                <div 
                  style={{
                    display:'flex', flexDirection:'column',
                    padding:'30px 40px', justifyContent:'space-between'
                  }}>
                  <div style={{display:'flex', flexDirection:'column',gap:10 }}>
                    <a href="/about" 
                      style={{ fontWeight:'bold', fontSize:'18px', 
                      }}>
                        about
                    </a>
                    <label>
                      <input role="switch" type="checkbox" id="toggle-switch"
                        checked={!isLanguageEn}
                        onChange={() => setIsLanguageEn(!isLanguageEn)}
                      />
                      <span className="slider round">
                        <span className="label-en"><img style={{width:'30px', height:'auto'}} src={iconEn} /></span>
                        <span className="label-kr"><img style={{width:'30px', height:'auto'}} src={iconKr} /></span>
                      </span>
                      <span style={{paddingLeft:'12px', fontSize:'18px'}}>{isLanguageEn? 'English':'한국어'}</span>
                    </label>

                    {/* <a href="mailto: inadvanced8282@gmail.com" style={{fontSize:'18px'}}>inadvanced8282@gmail.com</a> */}
                  </div>

                  {/* <div style={{display:'flex', gap:'8px', fontSize:'18px',}}>
                    <span>Seoul, </span>
                    <span style={{fontWeight:'bold'}}>Korea</span>
                    <span>
                      {koreaTime}
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
            <nav 
              className='nav-bar'
            >
              <div 
                style={{
                  display:'flex', alignItems:'center',
                  padding:'0px 36px', gap:'24px'
                }}>
                <a href="/">
                  <span style={{ fontSize:'16px' ,fontWeight:'bold'}}>MIRIKIM</span>
                  {/* <img style={{width:'38px', height:'auto'}} src={logo} /> */}
                </a>
              </div>
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', paddingTop:'20px'}}>
                <div
                  style={{
                    display:'flex', alignItems:'center',
                    paddingRight:'36px', gap:'24px'
                  }}>
                    <a href="/about" 
                      style={{ fontWeight:'bold', fontSize:'12px', 
                        borderBottom:'1px solid #EAEAEA', 
                      }}>
                        about
                    </a>
                    <a href="mailto: inadvanced8282@gmail.com" style={{fontSize:'12px', fontWeight:'bold', cursor:'url(./contents/images/cursor-pointer.svg), pointer'}}>inadvanced8282@gmail.com</a>
                    <label>
                      <input role="switch" type="checkbox" id="toggle-switch"
                        checked={!isLanguageEn}
                        onChange={() => setIsLanguageEn(!isLanguageEn)}
                      />
                      <span className="slider round">
                        <span className="label-en"><img style={{width:'30px', height:'auto'}} src={iconEn} /></span>
                        <span className="label-kr"><img style={{width:'30px', height:'auto'}} src={iconKr} /></span>
                      </span>
                    </label>
                </div>
                <div style={{display:'flex', gap:'8px', fontSize:'12px', transform: 'rotate(90deg) translate(80px, -20px)',}}>
                  <span>Seoul, </span>
                  <span style={{fontWeight:'bold'}}>Korea</span>
                  <span>
                    {koreaTime}
                  </span>
                </div>
              </div>
            </nav>
          )}          
        </div>
        <Routes>
          <Route path="/" element={<Home isLanguageEn={isLanguageEn} />} />
          <Route path="/about" element={<About isLanguageEn={isLanguageEn} />} />
        </Routes>
    </Router>
  );
  }
  
  export default AppRouter;  
  