import React, { useState } from 'react';
import xIcon from "../../contents/images/icon-x.svg"

const Dropdown = ({ items }) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  const isDropdownOpen = (index) => openDropdown === index;

  return (
    <div className="dropdown-container">
      {items.map((item, index) => (
        <div key={index} style={{borderBottom:'1px solid #8B95A1'}}>
          <div style={{display:'flex', width:'100%', height:'100%',alignItems:'center', gap:'6px', paddingBottom:'10px', cursor: 'url(../../contents/images/cursor-pointer.svg), pointer', }} onClick={() => toggleDropdown(index)}>
            <img
              className={`rotated-icon${isDropdownOpen(index) ? ' open' : ''}`}
              style={{
                width: '14px',
              }}
              src={xIcon}
            />
            <button
              style={{ fontWeight:'bold', width:'100%', 
                color: isDropdownOpen(index)?"#eaeaea": openDropdown===null?'#eaeaea':'#8B95A1' 
              }}
            >
              {item.title}
            </button>
          </div>
          {isDropdownOpen(index) && (
            <div className="dropdown-content">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
