import './About.css';
import React, { useState } from 'react';
import { compareSize, useResponsive } from '../utils/responsive.ts';
import { TextContents, loadingPercentage } from '../contents/TextContents.jsx';
import Footer from '../components/organisms/Footer.jsx';
import Dropdown from '../components/molecules/Dropdown.jsx';
import CanvasComponent from '../components/molecules/Effect';
import ContactUs from '../components/molecules/Email.jsx';

export default function About({ isLanguageEn }) {
  const size = useResponsive('txl');

  const dropdownItems = [
    {
      title: 'major skills',
      content: (
        <div>
            • JavaScript, React, Typescript, HTML/CSS, <br/>
            • Figma, Adobe (Illustrator, XD, Photoshop) <br/>
            ... and lot more ;)
        </div>
      ),
    },
    {
      title: 'what I can do',
      content: (
        <div>
          • {isLanguageEn ? TextContents.about.what_i_can_do_en_1 : TextContents.about.what_i_can_do_kr_1} <br/>
          • {isLanguageEn ? TextContents.about.what_i_can_do_en_2 : TextContents.about.what_i_can_do_kr_2} <br/>
        </div>
      ),
    },
    {
      title: 'basic information',
      content: ( <div color='#8B95A1 !important'>
        • additional url: <a href="https://github.com/mirinteractive" target="_blank">Github</a>, <a href="mailto: inadvanced8282@gmail.com">Email</a> <br/>
        • awards: <a href="http://contests.gdusa.com/gdusa-contest-winner?cc=aida20&ids=0|10780&iy=&im=" target="_blank">GD USA</a>, <a href="https://www.c2award.com/winners/c2a/2020/4910/" target="_blank">C2A</a> <br/>
        • education: MICA (Maryland Institute College of Art) <br/>
        • work with: Superb AI, WayneHills Bryant AI, Mondrian AI 
      </div>),
    },
    {
      title: 'contact me',
      content: (<ContactUs />),
    },
  ];

  return (
    <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
      {compareSize(`${size} <= md`) ? (
        <div className="container-mobile">
          <div style={{padding:'40px 24px 0px 24px' ,color:'#eaeaea',}}>
          <div>
              <div style={{ paddingBottom: '24px', fontSize: '24px', fontWeight: 'bold' }}>
                {isLanguageEn ? TextContents.about.main_title_en : TextContents.about.main_title_kr}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, fontSize: '16px', lineHeight:'1.2', color:'#b2b2b2' }}>
                <div>{isLanguageEn ? TextContents.about.main_text_en_1 : TextContents.about.main_text_kr_1}</div>
                <div>{isLanguageEn ? TextContents.about.main_text_en_2 : TextContents.about.main_text_kr_2}</div>
                <div>
                  <div>{isLanguageEn ? TextContents.about.main_text_en_3 : TextContents.about.main_text_kr_3}</div>
                  <div>{isLanguageEn ? TextContents.about.main_text_en_4 : TextContents.about.main_text_kr_4}</div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', padding:'36px 0px' }}>
              <Dropdown items={dropdownItems} />
            </div>
          </div>
          <CanvasComponent canvasWidth='1' canvasHeight='3' mouseClick={false}  />
          <Footer />
        </div>
      ) : (
        <div className="container-web" style={{ width: '100%', maxWidth: '1512px', padding: '30px 36px 0px', overflowX:'hidden' }}>
          <div style={{ width: '50vw', paddingTop: '60px' }}>
            <div>
              <div style={{ paddingBottom: '24px', fontSize: '36px', fontWeight: 'bold' }}>
                {isLanguageEn ? TextContents.about.main_title_en : TextContents.about.main_title_kr}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, fontSize: '16px', lineHeight:'1.2', color:'#b2b2b2' }}>
                <div>{isLanguageEn ? TextContents.about.main_text_en_1 : TextContents.about.main_text_kr_1}</div>
                <div>{isLanguageEn ? TextContents.about.main_text_en_2 : TextContents.about.main_text_kr_2}</div>
                <div>
                  <div>{isLanguageEn ? TextContents.about.main_text_en_3 : TextContents.about.main_text_kr_3}</div>
                  <div>{isLanguageEn ? TextContents.about.main_text_en_4 : TextContents.about.main_text_kr_4}</div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', padding:'36px 0px' }}>
              <Dropdown items={dropdownItems} />
            </div>
          </div>
          <div style={{ width: '50vw' }}>
            <CanvasComponent canvasWidth='2' canvasHeight='1.08' mouseClick={true} />
          </div>
        </div>
      )}
    </div>
  );
}

