import React from 'react';
import './LoadingScreen.css'

export default function LoadingScreen({ loadingPercentage }) {
  const innerCircleRadius = 107.083;
  const innerCircleStrokeWidth = 20;
  const outerCircleRadius = 124;
  const outerCircleStrokeWidth = 2;

  // Calculate the strokeDasharray and strokeDashoffset for the inner circle.
  const circumference = 2 * Math.PI * innerCircleRadius;
  const dashOffset = circumference - (loadingPercentage / 100) * circumference;

  return (
    <div className="loading-screen">
      <div className="loading-circle">
      <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="125.417" cy="124.583" r={innerCircleRadius} stroke="#EAEAEA" strokeWidth={innerCircleStrokeWidth}>
          <animate
            attributeName="stroke-opacity"
            dur="3s"
            values="0.3;1;0.3"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="125" cy="125" r={outerCircleRadius} stroke="#8B95A1" strokeWidth={outerCircleStrokeWidth} />
      </svg>
      <div className="percentage-text" style={{ color: loadingPercentage !== 100 ? '#8B95A1' : '#eaeaea' }}>
        {loadingPercentage}%
      </div>
    </div>
  </div>
  );
}
