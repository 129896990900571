// eslint-disable-next-line no-unused-vars
export function throttle(func: (...arg: Array<any>) => void, limit = 1000) {
    let inThrottle = false;
  
    return function (...args: Array<any>) {
      const context = this;
  
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }
  